import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import TokensService from '@services/tokensService'
import usersService from '@services/users'

import { BooleanValue, BrowserStorage, ResponseCode, UserSettingsKey } from '@const/consts'
import urls from '@const/urls'

import { resetApprovalScheme } from '@store/modules/approvalScheme/actions'
import { resetContractors } from '@store/modules/contractors/actions'
import { resetDivisions } from '@store/modules/divisions/actions'
import { resetDocuments } from '@store/modules/documents/actions'
import { resetDocumentsOperation } from '@store/modules/documentsOperation/actions'
import { resetGroup } from '@store/modules/group/actions'
import { resetGroups } from '@store/modules/groups/actions'
import { resetHandbooks } from '@store/modules/handbooks/actions'
import { resetMetadata } from '@store/modules/metadata/actions'
import { resetNavigationAfterOrgChange } from '@store/modules/navigation/actions'
import { resetPagination } from '@store/modules/pagination/actions'
import { resetReceipts } from '@store/modules/receipts/actions'
import { resetRoutes } from '@store/modules/routes/actions'
import { resetStartup } from '@store/modules/startup/actions'
import { resetTasks } from '@store/modules/tasks/actions'
import { resetUsers } from '@store/modules/users/actions'
import { resetError404, resetError500, setJustDeletedOrg } from '@store/modules/utils/actions'

import { INewOrganization, IUserOrganization } from '@store/types/commonTypes'
import {
  IActionSetActiveOrganization,
  IActionSetExchangeParticipant,
  IActionSetFlowFunctionality,
  IActionSetUserExtendedInfo,
  IActionSetUserOrganizations,
  IActionSetUserSettings,
  IActionSetProfile,
  IEmailPasswordAuth,
  IUpdatedProfile,
  IProfile,
  IUserExtendedInfo,
  SyncThunk,
  UserActions,
  UserActionTypes,
  UserThunkAction,
  UserThunkDispatch,
  IOrganization,
  ITokensFromAuth,
  IActionSetPhoneAuthId,
  IPhoneAuthConfirm,
  IActionIsShowPhoneInput,
  IUserSettings,
  IFactorAuthConfirm,
  IActionSetLogoOrg
} from './types'
import { getBaseUrl } from '@app/utils/utils'

// actions
const setActiveOrganizationAction: ActionCreator<Action> = (
  organization: IUserOrganization
): IActionSetActiveOrganization => ({
  payload: organization,
  type: UserActionTypes.SET_ACTIVE_ORGANIZATION
})

const setUserOrganizationsAction: ActionCreator<Action> = (
  organizations: IUserOrganization[]
): IActionSetUserOrganizations => ({
  payload: organizations,
  type: UserActionTypes.SET_USER_ORGANIZATIONS
})

const setProfileAction: ActionCreator<Action> = (user: IProfile): IActionSetProfile => ({
  payload: user,
  type: UserActionTypes.SET_PROFILE
})

const setUserExtendedInfoAction: ActionCreator<Action> = (info: IUserExtendedInfo): IActionSetUserExtendedInfo => ({
  payload: info,
  type: UserActionTypes.SET_USER_EXTENDED_INFO
})

const setExchangeParticipantAction: ActionCreator<Action> = (
  isExchangeParticipant: boolean
): IActionSetExchangeParticipant => ({
  payload: isExchangeParticipant,
  type: UserActionTypes.SET_EXCHANGE_PARTICIPANT
})

const setFlowFunctionalityAction: ActionCreator<Action> = (
  isFlowFunctionalityEnabled: boolean
): IActionSetFlowFunctionality => ({
  payload: isFlowFunctionalityEnabled,
  type: UserActionTypes.SET_FLOW_FUNCTIONALITY
})

const setUserSettingsAction: ActionCreator<Action> = (userSettings: IUserSettings): IActionSetUserSettings => ({
  payload: userSettings,
  type: UserActionTypes.SET_USER_SETTINGS
})

const setPhoneAuthIdAction: ActionCreator<Action> = (id: string): IActionSetPhoneAuthId => ({
  payload: id,
  type: UserActionTypes.SET_PHONE_AUTH_ID
})

const setIsShowPhoneInputAction: ActionCreator<Action> = (status: boolean): IActionIsShowPhoneInput => ({
  payload: status,
  type: UserActionTypes.IS_SHOW_PHONE_INPUT
})

const setLogoOrg: ActionCreator<Action> = (file: string): IActionSetLogoOrg => ({
  payload: file,
  type: UserActionTypes.SET_LOGO_ORG
})

export const actions: UserActions = {
  setActiveOrganization: setActiveOrganizationAction,
  setUserOrganizations: setUserOrganizationsAction,
  setUserExtendedInfo: setUserExtendedInfoAction,
  setProfile: setProfileAction,
  setExchangeParticipant: setExchangeParticipantAction,
  setFlowFunctionality: setFlowFunctionalityAction,
  setUserSettings: setUserSettingsAction,
  setPhoneAuthId: setPhoneAuthIdAction,
  setIsShowPhoneInput: setIsShowPhoneInputAction,
  setLogoOrg: setLogoOrg
}

// thunks

export const getProfile: ActionCreator<UserThunkAction> = (lastOrgOguid?: string) => (
  dispatch: UserThunkDispatch,
  getState
): Promise<AxiosResponse<IProfile>> =>
  usersService
    .getProfile()
    .then((resp: AxiosResponse<IProfile>) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.setProfile(resp.data))
        const { orgs } = resp.data

        if (!orgs.length) {
          return resp
        }

        const userSettings = getState().user.settings
        let settingsOrgs
        let updatedLastOrg

        if (orgs.length === 1) {
          // keep only the active organization
          settingsOrgs = {
            [orgs[0].oguid]: {
              ...userSettings?.orgs?.[orgs[0].oguid],
              [UserSettingsKey.IS_LAST]: true
            }
          }
        } else {
          // check whether the organization from user/settings is in the list of available organizations
          // keep only relevant ones
          settingsOrgs = Object.entries(userSettings?.orgs ?? {})?.reduce((acc, [orgKey, orgValue]) => {
            const isOrgAvailable = !!orgs.find(org => org.oguid === orgKey)

            return isOrgAvailable
              ? { ...acc, [orgKey]: { ...orgValue } }
              : acc
          }, {})
        }

        const existedActiveOrganizationOguid = window.localStorage.getItem(BrowserStorage.ACTIVE_ORGANIZATION)
        let existedActiveOrganization

        if (existedActiveOrganizationOguid) {
          existedActiveOrganization = orgs.find(
            (org: IUserOrganization) => org.oguid === existedActiveOrganizationOguid
          )
        }

        const lastOrg = orgs.find((org: IUserOrganization) => org.oguid === lastOrgOguid)
        const activeOrganization = existedActiveOrganization ?? lastOrg ?? orgs[0]

        if (!existedActiveOrganization && !lastOrg) {
          const userSettings = getState().user.settings
          updatedLastOrg = {
            [orgs[0].oguid]: {
              ...userSettings?.orgs?.[orgs[0].oguid],
              [UserSettingsKey.IS_LAST]: true
            }
          }
        }

        const updatedUserSettings = {
          ...userSettings,
          orgs: {
            ...settingsOrgs,
            ...updatedLastOrg
          }
        }

        usersService.updateUserSettings(updatedUserSettings)
          .catch((err: AxiosError) => Promise.reject(err))

        if (!existedActiveOrganization) {
          window.localStorage.setItem(BrowserStorage.ACTIVE_ORGANIZATION, activeOrganization.oguid)
        }

        dispatch(actions.setActiveOrganization(activeOrganization))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const requestUserOrganizations: ActionCreator<UserThunkAction> = () => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> =>
  usersService.requestUserOrganizations()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        const { orgs } = resp.data

        if (!orgs.length) {
          return resp
        }

        dispatch(actions.setUserOrganizations(orgs))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const changeOrganization: SyncThunk = (organization: IUserOrganization) => (dispatch: any, getState): void => {
  const userSettings = getState().user.settings

  const updatedUserSettings = {
    ...userSettings,
    orgs: {
      ...userSettings?.orgs,
      [organization.oguid]: {
        ...userSettings?.orgs?.[organization.oguid],
        [UserSettingsKey.IS_LAST]: true
      }
    }
  }

  Object.keys(updatedUserSettings?.orgs).forEach(item => {
    if (item !== organization.oguid) delete updatedUserSettings.orgs[item][UserSettingsKey.IS_LAST]
  })

  usersService.updateUserSettings(updatedUserSettings)
    .catch((err: AxiosError) => Promise.reject(err))

  window.localStorage.setItem(BrowserStorage.ACTIVE_ORGANIZATION, organization.oguid)
  dispatch(actions.setActiveOrganization(organization))
  dispatch(setJustDeletedOrg(null))
}

export const resetStoreAfterOrganizationChange: SyncThunk = () => (dispatch: UserThunkDispatch): void => {
  dispatch(resetApprovalScheme)
  dispatch(resetContractors)
  dispatch(resetDivisions)
  dispatch(resetDocuments)
  dispatch(resetDocumentsOperation)
  dispatch(resetGroup)
  dispatch(resetGroups)
  dispatch(resetHandbooks)
  dispatch(resetMetadata)
  dispatch(resetNavigationAfterOrgChange)
  dispatch(resetPagination)
  dispatch(resetReceipts)
  dispatch(resetRoutes)
  dispatch(resetStartup)
  dispatch(resetTasks)
  dispatch(resetUsers)
  dispatch(resetError404)
  dispatch(resetError500)
}

export const getRequisites: ActionCreator<UserThunkAction> = (orgOguid: string) => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> => usersService.getRequisites(orgOguid)
  .then((resp: AxiosResponse<IOrganization>) => {
    const { isExchangeParticipant, isFlowFunctionalityEnabled } = resp.data

    dispatch(actions.setExchangeParticipant(isExchangeParticipant))
    dispatch(actions.setFlowFunctionality(isFlowFunctionalityEnabled))

    return resp
  })
  .catch((err: AxiosError) => Promise.reject(err))

export const uploadLogoOrg: ActionCreator<UserThunkAction> = (logo: string) => async (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> => {
  try {
    const resp = await usersService.uploadLogoOrg(logo)
    dispatch(actions.setLogoOrg(logo))

    return resp
  } catch (err) {
    return await Promise.reject(err)
  }
}

export const getLogoOrg: ActionCreator<UserThunkAction> = () => async (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse<string>> => {
  try {
    const resp = await usersService.getLogoOrg()
    dispatch(actions.setLogoOrg(resp.data))

    return resp
  } catch (err) {
    dispatch(actions.setLogoOrg(null))

    return await Promise.reject(err)
  }
}

export const deleteLogoOrg: ActionCreator<UserThunkAction> = () => async (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> => {
  try {
    const resp = await usersService.deleteLogoOrg()
    dispatch(actions.setLogoOrg(null))

    return resp
  } catch (err) {
    return await Promise.reject(err)
  }
}

export const getUserExtendedInfo: SyncThunk = () => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse<IUserExtendedInfo>> =>
  usersService
    .getExtendedInfo()
    .then((resp: AxiosResponse<IUserExtendedInfo>) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.setUserExtendedInfo(resp.data))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))

export const getOrganization: SyncThunk = (orgOguid: string) => (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
  usersService.getRequisites(orgOguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.setActiveOrganization(resp.data))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))

export const updateProfile: ActionCreator<UserThunkAction> = (profile: IUpdatedProfile) => {
  return (): Promise<AxiosResponse> =>
    usersService
      .updateProfile(profile)
      .then((resp: AxiosResponse) => {
        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
}

export const addNewOrganization: ActionCreator<UserThunkAction> = (organizationData?: INewOrganization) =>
  (dispatch: UserThunkDispatch, getState): Promise<any> =>
    usersService.addNewOrganization(organizationData)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.POST) {
          const { orgs } = getState().user.profile

          const newOrgs = [...orgs, resp.data]

          dispatch(actions.setUserOrganizations(newOrgs))
        }
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const signin: ActionCreator<UserThunkAction> = (data: IEmailPasswordAuth, isRemember: boolean) => (): Promise<AxiosResponse> =>
  usersService.signin(data)
    .then((resp: AxiosResponse<ITokensFromAuth>) => {
      const { data, status } = resp

      if (status === ResponseCode.GET) {
        // check whether authorization was completed earlier on another tab
        const user = TokensService.getUserFromCookies()

        // if it was, then delete authorization data
        if (user) {
          TokensService.clearCookies(user)
        }

        // saving new data ('Remember me' flag, user and tokens)
        const isRememberToService = BooleanValue[String(isRemember).toUpperCase()]

        TokensService.setCookies(data, isRememberToService)
      }

      return resp
    })

export const getUserSettings: ActionCreator<UserThunkAction> = () => {
  return (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
    usersService
      .getUserSettings()
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setUserSettings(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
}

export const updateUserSettings: ActionCreator<UserThunkAction> = (userSettings: IUserSettings) => {
  return (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
    usersService
      .updateUserSettings(userSettings)
      .then((resp: AxiosResponse) => {
        dispatch(actions.setUserSettings(userSettings))

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
}

export const phoneAuthConfirm: ActionCreator<UserThunkAction> = (data: IPhoneAuthConfirm, isRemember: boolean) => (): Promise<AxiosResponse> =>
  usersService.phoneAuthConfirm(data)
    .then((resp: AxiosResponse<ITokensFromAuth>) => {
      const { data, status } = resp

      if (status === ResponseCode.POST) {
        // check whether authorization was completed earlier on another tab
        const user = TokensService.getUserFromCookies()

        // if it was, then delete authorization data
        if (user) {
          TokensService.clearCookies(user)
        }

        // saving new data ('Remember me' flag, user and tokens)
        const isRememberToService = BooleanValue[String(isRemember).toUpperCase()]

        TokensService.setCookies(data, isRememberToService)
      }

      return resp
    })

export const factorAuthConfirm: ActionCreator<UserThunkAction> = (data: IFactorAuthConfirm, isRemember: boolean) => (): Promise<AxiosResponse> =>
  usersService.factorAuthConfirm(data)
    .then((resp: AxiosResponse<ITokensFromAuth>) => {
      const { data, status } = resp

      if (status === ResponseCode.POST) {
        // check whether authorization was completed earlier on another tab
        const user = TokensService.getUserFromCookies()

        // if it was, then delete authorization data
        if (user) {
          TokensService.clearCookies(user)
        }

        // saving new data ('Remember me' flag, user and tokens)
        const isRememberToService = BooleanValue[String(isRemember).toUpperCase()]

        TokensService.setCookies(data, isRememberToService)
      }

      return resp
    })

export const SSOAuth: ActionCreator<UserThunkAction> = (oguid: string) => (): Promise<AxiosResponse> =>
  usersService.SSOAuth(oguid)
    .then((resp: AxiosResponse<ITokensFromAuth>) => {
      const { data, status } = resp

      if (status === ResponseCode.GET) {
        if (data.status === ResponseCode.GET) {
          // check whether authorization was completed earlier on another tab
          const user = TokensService.getUserFromCookies()

          // if it was, then delete authorization data
          if (user) {
            TokensService.clearCookies(user)
          }

          TokensService.setCookies(data, 'false')

        } else if (data.status === ResponseCode.NOT_FOUND) {
          const url = `${getBaseUrl()}${urls.users.SSORegistrations.replace('{{ oguid }}', oguid)}`

          window.location.href = url
        }
      }

      return resp
    })
