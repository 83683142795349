// Below is a clarification on the keys for singular/plural
/* const englishLanguageKey = {
  key: [ 1 ],
  key_plural: [ 0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 ]
} */

/* const russianLanguageKey = {
  key_0: [ 1, 21, 31, 41, 51, 61, 71, 81, 91 ],
  key_1: [ 2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94 ],
  key_2: [0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 26, 27, 28, 29, 30, 35, 36, 37, 38, 39, 40, 45, 46, 47, 48, 49, 50, 55, 56, 57, 58, 59, 60, 65, 66, 67, 68, 69, 70, 75, 76, 77, 78, 79, 80, 85, 86, 87, 88, 89, 90, 95, 96, 97, 98, 99, 100]
} */

const mockTransFunc = (arg) => arg

export const AccessModes = {
  ALLOWED: mockTransFunc('administrator:fields.accessMode.allowed'),
  BLOCKED: mockTransFunc('administrator:fields.accessMode.blocked'),
  INITIATOR: mockTransFunc('administrator:fields.accessMode.initiator')
}

export const Administrator = {
  docTypesGroupValidation: mockTransFunc('administrator:documentTypes.group.validation'),
  nameRequired: mockTransFunc('administrator:division.nameRequired'),
  nameValidation: mockTransFunc('administrator:division.nameValidation')
}

export const BooleanOptions = {
  true: mockTransFunc('common:yes'),
  false: mockTransFunc('common:no')
}

export const Common = {
  active: mockTransFunc('common:active'),
  blocked: mockTransFunc('common:blocked'),
  division: mockTransFunc('common:division'),
  inactive: mockTransFunc('common:inactive'),
  password: mockTransFunc('common:password'),
  sum: mockTransFunc('common:sum'),
  vat: mockTransFunc('common:vat'),
  ogrnRu: mockTransFunc('common:ogrn.ru'),
  ogrnGlobal: mockTransFunc('common:ogrn.global'),
  ogrnCombr: mockTransFunc('common:ogrn.combr'),
  ogrnCoza: mockTransFunc('common:ogrn.coza'),
  regexpError: mockTransFunc('common:valueError')
}

export const Constraints = {
  codeRegexp: mockTransFunc('administrator:constraints.codeRegexp'),
  fields: mockTransFunc('administrator:constraints.fields')
}

export const ConstraintsTypeLabel = {
  notNull: mockTransFunc('administrator:constraints.typeLabel.notNull'),
  unique: mockTransFunc('administrator:constraints.typeLabel.unique'),
  allowedInRoute: mockTransFunc('administrator:constraints.typeLabel.allowedInRoute')
}

export const ContractorSuggest = {
  empty: mockTransFunc('contractor:suggest.empty'),
  hint: mockTransFunc('contractor:suggest.hint'),
  placeholderRu: mockTransFunc('contractor:suggest.placeholder.ru'),
  placeholderGlobal: mockTransFunc('contractor:suggest.placeholder.global'),
  placeholderCombr: mockTransFunc('contractor:suggest.placeholder.combr'),
  placeholderCoza: mockTransFunc('contractor:suggest.placeholder.coza')
}

export const ContractorValidation = {
  addrText: mockTransFunc('contractor:validation.addrText'),
  foreignAddress: mockTransFunc('contractor:validation.foreignAddress'),
  foreignCountry: mockTransFunc('contractor:validation.foreignCountry'),
  inn: mockTransFunc('contractor:validation.inn'),
  kpp: mockTransFunc('contractor:validation.kpp'),
  name: mockTransFunc('contractor:validation.name'),
  ogrn: mockTransFunc('contractor:validation.ogrn'),
  ogrnForeign: mockTransFunc('contractor:validation.ogrnForeign')
}

export const CryptoTexts = {
  declinedSignCount: mockTransFunc('crypto:declinedSignCount'),
  signedAndSaveCount: mockTransFunc('crypto:signedAndSaveCount'),
  signedAndSendCount: mockTransFunc('crypto:signedAndSendCount'),
  signedCount: mockTransFunc('crypto:signedCount')
}

export const Currency = {
  AED: mockTransFunc('currency:AED'),
  AFN: mockTransFunc('currency:AFN'),
  ALL: mockTransFunc('currency:ALL'),
  AMD: mockTransFunc('currency:AMD'),
  ANG: mockTransFunc('currency:ANG'),
  AOA: mockTransFunc('currency:AOA'),
  ARS: mockTransFunc('currency:ARS'),
  AUD: mockTransFunc('currency:AUD'),
  AWG: mockTransFunc('currency:AWG'),
  AZN: mockTransFunc('currency:AZN'),
  BAM: mockTransFunc('currency:BAM'),
  BBD: mockTransFunc('currency:BBD'),
  BDT: mockTransFunc('currency:BDT'),
  BGN: mockTransFunc('currency:BGN'),
  BHD: mockTransFunc('currency:BHD'),
  BIF: mockTransFunc('currency:BIF'),
  BMD: mockTransFunc('currency:BMD'),
  BND: mockTransFunc('currency:BND'),
  BOB: mockTransFunc('currency:BOB'),
  BRL: mockTransFunc('currency:BRL'),
  BSD: mockTransFunc('currency:BSD'),
  BTN: mockTransFunc('currency:BTN'),
  BWP: mockTransFunc('currency:BWP'),
  BYN: mockTransFunc('currency:BYN'),
  BZD: mockTransFunc('currency:BZD'),
  CAD: mockTransFunc('currency:CAD'),
  CDF: mockTransFunc('currency:CDF'),
  CHF: mockTransFunc('currency:CHF'),
  CLP: mockTransFunc('currency:CLP'),
  CNY: mockTransFunc('currency:CNY'),
  COP: mockTransFunc('currency:COP'),
  COU: mockTransFunc('currency:COU'),
  CRC: mockTransFunc('currency:CRC'),
  CUC: mockTransFunc('currency:CUC'),
  CUP: mockTransFunc('currency:CUP'),
  CVE: mockTransFunc('currency:CVE'),
  CZK: mockTransFunc('currency:CZK'),
  DJF: mockTransFunc('currency:DJF'),
  DKK: mockTransFunc('currency:DKK'),
  DOP: mockTransFunc('currency:DOP'),
  DZD: mockTransFunc('currency:DZD'),
  EGP: mockTransFunc('currency:EGP'),
  ERN: mockTransFunc('currency:ERN'),
  ETB: mockTransFunc('currency:ETB'),
  EUR: mockTransFunc('currency:EUR'),
  FJD: mockTransFunc('currency:FJD'),
  FKP: mockTransFunc('currency:FKP'),
  GBP: mockTransFunc('currency:GBP'),
  GEL: mockTransFunc('currency:GEL'),
  GHS: mockTransFunc('currency:GHS'),
  GIP: mockTransFunc('currency:GIP'),
  GMD: mockTransFunc('currency:GMD'),
  GNF: mockTransFunc('currency:GNF'),
  GTQ: mockTransFunc('currency:GTQ'),
  GYD: mockTransFunc('currency:GYD'),
  HKD: mockTransFunc('currency:HKD'),
  HNL: mockTransFunc('currency:HNL'),
  HRK: mockTransFunc('currency:HRK'),
  HTG: mockTransFunc('currency:HTG'),
  HUF: mockTransFunc('currency:HUF'),
  IDR: mockTransFunc('currency:IDR'),
  ILS: mockTransFunc('currency:ILS'),
  INR: mockTransFunc('currency:INR'),
  IQD: mockTransFunc('currency:IQD'),
  IRR: mockTransFunc('currency:IRR'),
  ISK: mockTransFunc('currency:ISK'),
  JMD: mockTransFunc('currency:JMD'),
  JOD: mockTransFunc('currency:JOD'),
  JPY: mockTransFunc('currency:JPY'),
  KES: mockTransFunc('currency:KES'),
  KGS: mockTransFunc('currency:KGS'),
  KHR: mockTransFunc('currency:KHR'),
  KMF: mockTransFunc('currency:KMF'),
  KPW: mockTransFunc('currency:KPW'),
  KRW: mockTransFunc('currency:KRW'),
  KWD: mockTransFunc('currency:KWD'),
  KYD: mockTransFunc('currency:KYD'),
  KZT: mockTransFunc('currency:KZT'),
  LAK: mockTransFunc('currency:LAK'),
  LBP: mockTransFunc('currency:LBP'),
  LKR: mockTransFunc('currency:LKR'),
  LRD: mockTransFunc('currency:LRD'),
  LSL: mockTransFunc('currency:LSL'),
  LYD: mockTransFunc('currency:LYD'),
  MAD: mockTransFunc('currency:MAD'),
  MDL: mockTransFunc('currency:MDL'),
  MGA: mockTransFunc('currency:MGA'),
  MKD: mockTransFunc('currency:MKD'),
  MMK: mockTransFunc('currency:MMK'),
  MNT: mockTransFunc('currency:MNT'),
  MOP: mockTransFunc('currency:MOP'),
  MRU: mockTransFunc('currency:MRU'),
  MUR: mockTransFunc('currency:MUR'),
  MVR: mockTransFunc('currency:MVR'),
  MWK: mockTransFunc('currency:MWK'),
  MXN: mockTransFunc('currency:MXN'),
  MYR: mockTransFunc('currency:MYR'),
  MZN: mockTransFunc('currency:MZN'),
  NAD: mockTransFunc('currency:NAD'),
  NGN: mockTransFunc('currency:NGN'),
  NIO: mockTransFunc('currency:NIO'),
  NOK: mockTransFunc('currency:NOK'),
  NPR: mockTransFunc('currency:NPR'),
  NZD: mockTransFunc('currency:NZD'),
  OMR: mockTransFunc('currency:OMR'),
  PAB: mockTransFunc('currency:PAB'),
  PEN: mockTransFunc('currency:PEN'),
  PGK: mockTransFunc('currency:PGK'),
  PHP: mockTransFunc('currency:PHP'),
  PKR: mockTransFunc('currency:PKR'),
  PLN: mockTransFunc('currency:PLN'),
  PYG: mockTransFunc('currency:PYG'),
  QAR: mockTransFunc('currency:QAR'),
  RON: mockTransFunc('currency:RON'),
  RSD: mockTransFunc('currency:RSD'),
  RUB: mockTransFunc('currency:RUB'),
  RWF: mockTransFunc('currency:RWF'),
  SAR: mockTransFunc('currency:SAR'),
  SBD: mockTransFunc('currency:SBD'),
  SCR: mockTransFunc('currency:SCR'),
  SDG: mockTransFunc('currency:SDG'),
  SEK: mockTransFunc('currency:SEK'),
  SGD: mockTransFunc('currency:SGD'),
  SHP: mockTransFunc('currency:SHP'),
  SLL: mockTransFunc('currency:SLL'),
  SOS: mockTransFunc('currency:SOS'),
  SRD: mockTransFunc('currency:SRD'),
  SSP: mockTransFunc('currency:SSP'),
  STN: mockTransFunc('currency:STN'),
  SVC: mockTransFunc('currency:SVC'),
  SYP: mockTransFunc('currency:SYP'),
  SZL: mockTransFunc('currency:SZL'),
  THB: mockTransFunc('currency:THB'),
  TJS: mockTransFunc('currency:TJS'),
  TMT: mockTransFunc('currency:TMT'),
  TND: mockTransFunc('currency:TND'),
  TOP: mockTransFunc('currency:TOP'),
  TRY: mockTransFunc('currency:TRY'),
  TTD: mockTransFunc('currency:TTD'),
  TWD: mockTransFunc('currency:TWD'),
  TZS: mockTransFunc('currency:TZS'),
  UAH: mockTransFunc('currency:UAH'),
  UGX: mockTransFunc('currency:UGX'),
  USD: mockTransFunc('currency:USD'),
  UYI: mockTransFunc('currency:UYI'),
  UYU: mockTransFunc('currency:UYU'),
  UZS: mockTransFunc('currency:UZS'),
  VES: mockTransFunc('currency:VES'),
  VND: mockTransFunc('currency:VND'),
  VUV: mockTransFunc('currency:VUV'),
  WST: mockTransFunc('currency:WST'),
  XAF: mockTransFunc('currency:XAF'),
  XCD: mockTransFunc('currency:XCD'),
  XDR: mockTransFunc('currency:XDR'),
  XOF: mockTransFunc('currency:XOF'),
  XPF: mockTransFunc('currency:XPF'),
  YER: mockTransFunc('currency:YER'),
  ZAR: mockTransFunc('currency:ZAR'),
  ZMW: mockTransFunc('currency:ZMW'),
  ZWL: mockTransFunc('currency:ZWL')
}

export const DayOptions = {
  today: mockTransFunc('filters:day.today'),
  tomorrow: mockTransFunc('filters:day.tomorrow'),
  yesterday: mockTransFunc('filters:day.yesterday')
}

export const Document = {
  actionType: {
    approve: mockTransFunc('document:actionType.approve'),
    placeholder: mockTransFunc('document:actionType.placeholder'),
    sign: mockTransFunc('document:actionType.sign')
  },
  approveAndSave: mockTransFunc('document:approveAndSave'),
  approveAndSend: mockTransFunc('document:approveAndSend'),
  cause: mockTransFunc('document:cause'),
  checks: mockTransFunc('document:checks'),
  comment: mockTransFunc('document:comment'),
  contractor: mockTransFunc('document:contractor'),
  createDate: mockTransFunc('document:createDate'),
  currency: mockTransFunc('document:currency'),
  date: mockTransFunc('document:date'),
  direction: mockTransFunc('document:direction'),
  edoStatus: mockTransFunc('document:edoStatus'),
  enterRecipient: mockTransFunc('document:enterRecipient'),
  filename: mockTransFunc('document:filename'),
  fillTodo: mockTransFunc('document:fillTodo'),
  flowCompletedTimestamp: mockTransFunc('document:flowCompletedTimestamp'),
  flowStageType: mockTransFunc('document:flowStageType'),
  flowStageUserName: mockTransFunc('document:flowStageUserName'),
  flowStartTimestamp: mockTransFunc('document:flowStartTimestamp'),
  grounds: mockTransFunc('document:grounds'),
  idDocDatasource: mockTransFunc('document:idDocDatasource'),
  initiator: mockTransFunc('document:initiator'),
  initiatorEmail: mockTransFunc('document:initiatorEmail'),
  initiatorPosition: mockTransFunc('document:initiatorPosition'),
  inPackage: mockTransFunc('document:inPackage'),
  inRelated: mockTransFunc('document:inRelated'),
  isExternal: mockTransFunc('document:isExternal'),
  isWorkflowFinished: mockTransFunc('document:isWorkflowFinished'),
  number: mockTransFunc('document:number'),
  sumWithVat: mockTransFunc('document:sumWithVat'),
  approval: mockTransFunc('document:taskType.approval'),
  processing: mockTransFunc('document:taskType.processing'),
  todo: mockTransFunc('document:taskType.todo'),
  select: mockTransFunc('document:select'),
  signing: mockTransFunc('document:taskType.signing'),
  signingSimple: mockTransFunc('document:taskType.signingSimple'),
  approved: mockTransFunc('document:taskResult.approved'),
  rejected: mockTransFunc('document:taskResult.rejected'),
  canceled: mockTransFunc('document:taskResult.canceled'),
  reassigned: mockTransFunc('document:taskResult.reassigned'),
  save: mockTransFunc('document:save'),
  sendForApprove: mockTransFunc('document:sentForApprove'),
  sendForSign: mockTransFunc('document:sentForSignature'),
  signAndSave: mockTransFunc('document:signAndSave'),
  signAndSend: mockTransFunc('document:signAndSend'),
  signed: mockTransFunc('document:taskResult.signed'),
  startProcess: mockTransFunc('document:startProcess'),
  type: mockTransFunc('document:type'),
  noDocumentsInPackage: mockTransFunc('document:noDocumentsInPackage'),
  packageDocuments: mockTransFunc('document:packageDocuments'),
  noRelatedDocuments: mockTransFunc('document:noRelatedDocuments'),
  relatedDocuments: mockTransFunc('document:relatedDocuments'),
  workflowStatuses: mockTransFunc('document:workflowStatuses'),
  sumWithoutVat: mockTransFunc('document:sumWithoutVat'),
  dependencyRuleError: mockTransFunc('document:dependencyRuleError'),
  calculationRuleError: mockTransFunc('document:calculationRuleError'),
  calculationMismatch: mockTransFunc('document:calculationMismatch'),
  preProcessingStatus: mockTransFunc('preProcessing:status:recognition'),
}

export const DocumentDirections = {
  INCOMING: mockTransFunc('document:directions.incoming'),
  OUTGOING: mockTransFunc('document:directions.outgoing'),
  INTERNAL: mockTransFunc('document:directions.internal'),
  INTERNAL_WITH_SIGNATURE: mockTransFunc('document:directions.internalWithSignature')
}

export const DocumentFileField = {
  add: mockTransFunc('document:fileField.add'),
  blockedBy: mockTransFunc('document:fileField.blockedBy'),
  delete: mockTransFunc('document:fileField.delete'),
  downloadCurrent: mockTransFunc('document:fileField.downloadCurrent'),
  me: mockTransFunc('document:fileField.me'),
  openEdit: mockTransFunc('document:fileField.openEdit'),
  openView: mockTransFunc('document:fileField.openView'),
  pickUp: mockTransFunc('document:fileField.pickUp'),
  publish: mockTransFunc('document:fileField.publish'),
  replace: mockTransFunc('document:fileField.replace'),
  unblock: mockTransFunc('document:fileField.unblock'),
  uploadedBy: mockTransFunc('document:fileField.uploadedBy'),
  version: mockTransFunc('document:fileField.version'),
  versions: mockTransFunc('document:fileField.versions'),
  versionsOpen: mockTransFunc('document:fileField.versionsOpen'),
}

export const Documents = {
  conflictSignActions: mockTransFunc('documents:conflictSignActions'),
  new: mockTransFunc('documents:new'),
  noDocsForSign: mockTransFunc('documents:noDocsForSign'),
  sendDocument: mockTransFunc('documents:sendDocument')
}

export const DocumentsDirections = {
  INCOMING: mockTransFunc('documents:directions.incoming'),
  OUTGOING: mockTransFunc('documents:directions.outgoing'),
  INTERNAL: mockTransFunc('documents:directions.internal')
}

export const TableFieldsDirectionsLabels = {
  INCOMING: mockTransFunc('document:directions.incoming'),
  OUTGOING: mockTransFunc('document:directions.outgoing')
}

export const DocumentTypes = {
  add: mockTransFunc('administrator:documentTypes.add'),
  added: mockTransFunc('administrator:documentTypes.added'),
  addField: mockTransFunc('administrator:documentTypes.addField'),
  addTable: mockTransFunc('administrator:documentTypes.addTable'),
  addingField: mockTransFunc('administrator:documentTypes.addingField'),
  addingSplitter: mockTransFunc('administrator:documentTypes.addingSplitter'),
  addLocalization: mockTransFunc('administrator:documentTypes.addLocalization'),
  confirmContent: mockTransFunc('administrator:documentTypes.confirmContent'),
  confirmFieldDeletedContent: mockTransFunc('administrator:documentTypes.confirmFieldDeletedContent'),
  confirmFieldDeletedTitle: mockTransFunc('administrator:documentTypes.confirmFieldDeletedTitle'),
  confirmSplitterDeletedContent: mockTransFunc('administrator:documentTypes.confirmSplitterDeletedContent'),
  confirmTitle: mockTransFunc('administrator:documentTypes.confirmTitle'),
  deleteLocalization: mockTransFunc('administrator:documentTypes.deleteLocalization'),
  dictTypePostfix: mockTransFunc('administrator:documentTypes.dictTypePostfix'),
  downloadTemplate: mockTransFunc('administrator:documentTypes.downloadTemplate'),
  edit: mockTransFunc('administrator:documentTypes.edit'),
  edited: mockTransFunc('administrator:documentTypes.edited'),
  editingField: mockTransFunc('administrator:documentTypes.editingField'),
  editingSplitter: mockTransFunc('administrator:documentTypes.editingSplitter'),
  fieldAdded: mockTransFunc('administrator:documentTypes.fieldAdded'),
  fieldAlertIsSystem: mockTransFunc('administrator:documentTypes.fieldAlertIsSystem'),
  fieldDeleted: mockTransFunc('administrator:documentTypes.fieldDeleted'),
  fields: mockTransFunc('administrator:documentTypes.fields'),
  fieldEdited: mockTransFunc('administrator:documentTypes.fieldEdited'),
  localization: mockTransFunc('administrator:documentTypes.localization'),
  localizationAlert: mockTransFunc('administrator:documentTypes.localizationAlert'),
  tableFields: mockTransFunc('administrator:documentTypes.tableFields')
}

export const DocumentTypesLabel = {
  accessMode: mockTransFunc('administrator:documentTypes.labels.accessMode'),
  comment: mockTransFunc('administrator:documentTypes.labels.comment'),
  documentTypeKey: mockTransFunc('administrator:documentTypes.labels.documentTypeKey'),
  field: mockTransFunc('administrator:documentTypes.labels.field'),
  fieldType: mockTransFunc('administrator:documentTypes.labels.type'),
  form: mockTransFunc('administrator:documentTypes.labels.form'),
  formCreate: mockTransFunc('administrator:documentTypes.labels.formCreate'),
  formCreateVisible: mockTransFunc('administrator:documentTypes.labels.formCreateVisible'),
  formOrder: mockTransFunc('administrator:documentTypes.labels.formOrder'),
  group: mockTransFunc('administrator:documentTypes.labels.group'),
  hint: mockTransFunc('administrator:documentTypes.labels.hint'),
  isAccessModeInherited: mockTransFunc('administrator:documentTypes.labels.isAccessModeInherited'),
  isArray: mockTransFunc('administrator:fields.labels.isArray'),
  isHidden: mockTransFunc('administrator:documentTypes.labels.isHidden'),
  isRequired: mockTransFunc('administrator:documentTypes.labels.isRequired'),
  isOverriding: mockTransFunc('administrator:documentTypes.labels.isOverriding'),
  key: mockTransFunc('administrator:documentTypes.labels.key'),
  label: mockTransFunc('administrator:documentTypes.labels.label'),
  list: mockTransFunc('administrator:documentTypes.labels.list'),
  listOrder: mockTransFunc('administrator:documentTypes.labels.listOrder'),
  required: mockTransFunc('administrator:documentTypes.labels.isRequired'),
  showForm: mockTransFunc('administrator:documentTypes.labels.showForm'),
  showList: mockTransFunc('administrator:documentTypes.labels.showList'),
  template: mockTransFunc('administrator:documentTypes.labels.template'),
  textAlign: mockTransFunc('administrator:documentTypes.labels.textAlign'),
  textAlignOptions: {
    CENTER: mockTransFunc('administrator:documentTypes.labels.textAlignOptions.center'),
    LEFT: mockTransFunc('administrator:documentTypes.labels.textAlignOptions.left'),
    RIGHT: mockTransFunc('administrator:documentTypes.labels.textAlignOptions.right')
  },
  title: mockTransFunc('administrator:documentTypes.labels.title'),
  type: mockTransFunc('administrator:documentTypes.labels.type'),
  width: mockTransFunc('administrator:documentTypes.labels.width')
}

export const DocumentTypeMessages = {
  autoDocumentNumber: {
    lengthMax: mockTransFunc('administrator:documentTypes.autoDocumentNumber.errors.lengthMax'),
    lengthMin: mockTransFunc('administrator:documentTypes.autoDocumentNumber.errors.lengthMin')
  },
  key: {
    regexp: mockTransFunc('administrator:documentTypes.errors.invalidKey'),
    required: mockTransFunc('administrator:documentTypes.errors.enterKey')
  },
  title: mockTransFunc('administrator:documentTypes.errors.enterTitle')
}

export const FieldMessages = {
  key: {
    regexp: mockTransFunc('administrator:fields.errors.invalidKey'),
    required: mockTransFunc('administrator:fields.errors.enterKey')
  },
  label: mockTransFunc('administrator:fields.errors.enterLabel'),
  order: mockTransFunc('administrator:fields.errors.order')
}

export const Fields = {
  add: mockTransFunc('administrator:fields.add'),
  added: mockTransFunc('administrator:fields.added'),
  addLocalization: mockTransFunc('administrator:fields.addLocalization'),
  confirmContent: mockTransFunc('administrator:fields.confirmContent'),
  confirmTitle: mockTransFunc('administrator:fields.confirmTitle'),
  edit: mockTransFunc('administrator:fields.edit'),
  edited: mockTransFunc('administrator:fields.edited'),
  localization: mockTransFunc('administrator:fields.localization'),
  view: mockTransFunc('administrator:fields.view')
}

export const FieldsLabel = {
  accessMode: mockTransFunc('administrator:fields.labels.accessMode'),
  dictType: mockTransFunc('administrator:fields.labels.dictType'),
  isArray: mockTransFunc('administrator:fields.labels.isArray'),
  hint: mockTransFunc('administrator:fields.labels.hint'),
  key: mockTransFunc('administrator:fields.labels.key'),
  label: mockTransFunc('administrator:fields.labels.label'),
  order: mockTransFunc('administrator:fields.labels.order'),
  placeholder: mockTransFunc('administrator:fields.labels.placeholder'),
  regexp: mockTransFunc('administrator:fields.labels.regexp'),
  showForm: mockTransFunc('administrator:fields.labels.showForm'),
  showList: mockTransFunc('administrator:fields.labels.showList'),
  type: mockTransFunc('administrator:fields.labels.type')
}

export const FieldTypes = {
  BOOLEAN: mockTransFunc('administrator:fields.type.boolean'),
  DATE: mockTransFunc('administrator:fields.type.date'),
  DATETIME: mockTransFunc('administrator:fields.type.datetime'),
  DICTIONARY: mockTransFunc('administrator:fields.type.dictionary'),
  DIRECTION: mockTransFunc('administrator:fields.type.direction'),
  FILE: mockTransFunc('administrator:fields.type.file'),
  NUMBER: mockTransFunc('administrator:fields.type.number'),
  STRING: mockTransFunc('administrator:fields.type.string')
}

export const DependentFields = {
  addDependency: mockTransFunc('administrator:dependentFields.addDependency'),
  editDependency: mockTransFunc('administrator:dependentFields.editDependency'),
  heading: mockTransFunc('administrator:dependentFields.heading'),
  dependencyType: mockTransFunc('administrator:dependentFields.dependencyType'),
  sourceValue: mockTransFunc('administrator:dependentFields.sourceValue'),
  field: mockTransFunc('administrator:dependentFields.field'),
  dependsOn: mockTransFunc('administrator:dependentFields.dependsOn'),
  operation: mockTransFunc('administrator:dependentFields.operation'),
  roundTo: mockTransFunc('administrator:dependentFields.roundTo'),
  decimalPlaces: mockTransFunc('administrator:dependentFields.decimalPlaces'),
  dependentField: mockTransFunc('administrator:dependentFields.dependentField'),
  dictionary: mockTransFunc('administrator:dependentFields.dictionary'),
  mathematical: mockTransFunc('administrator:dependentFields.mathematical'),
  tooltips: {
    dependencyType: {
      dictionaryTitle: mockTransFunc('administrator:dependentFields.tooltips.dependencyType.dictionary.title'),
      dictionaryContent: mockTransFunc('administrator:dependentFields.tooltips.dependencyType.dictionary.content'),
      mathematicalTitle: mockTransFunc('administrator:dependentFields.tooltips.dependencyType.mathematical.title'),
      mathematicalContent: mockTransFunc('administrator:dependentFields.tooltips.dependencyType.mathematical.content')
    }
  },
  math: {
    MULTIPLY: mockTransFunc('administrator:dependentFields.multiply'),
    ADD: mockTransFunc('administrator:dependentFields.add')
  },
  unknownOperation: mockTransFunc('administrator:dependentFields.unknownOperation')
}

export const FiltersName = {
  author: mockTransFunc('filters:author'),
  certificateRequestStatus: mockTransFunc('filters:certificateRequestStatus'),
  certStatus: mockTransFunc('filters:certStatus'),
  contractor: mockTransFunc('filters:contractor'),
  createdDate: mockTransFunc('filters:createdDate'),
  direction: mockTransFunc('filters:direction'),
  division: mockTransFunc('filters:division'),
  documentType: mockTransFunc('filters:documentType'),
  flowResult: mockTransFunc('filters:flowResult'),
  flowStageType: mockTransFunc('filters:flowStageType'),
  flowStageUserName: mockTransFunc('filters:flowStageUserName'),
  flowState: mockTransFunc('filters:flowState'),
  flowStatus: mockTransFunc('filters:flowStatus'),
  flowStarted: mockTransFunc('filters:flowStarted'),
  flowCompleted: mockTransFunc('filters:flowCompleted'),
  group: mockTransFunc('filters:group'),
  accessGroup: mockTransFunc('common:accessGroup'),
  initiator: mockTransFunc('filters:initiator'),
  isActivated: mockTransFunc('filters:isActivated'),
  isCertificateExist: mockTransFunc('filters:isCertificateExist'),
  isCertificateExpired: mockTransFunc('filters:isCertificateExpired'),
  isValidated: mockTransFunc('filters:isValidated'),
  role: mockTransFunc('filters:role'),
  roleRestrictions: mockTransFunc('user:roleRestrictions'),
  status: mockTransFunc('filters:status'),
  workflowStatus: mockTransFunc('filters:workflowStatus'),
  displaying: mockTransFunc('filters:displaying'),
  preProcessingStatus: mockTransFunc('preProcessing:status:recognition'),
  federation: mockTransFunc('user:federation'),
}

export const FiltersResult = {
  after: mockTransFunc('filters:result.after'),
  before: mockTransFunc('filters:result.before'),
  day: mockTransFunc('filters:result.day'),
  '%like': mockTransFunc('filters:result.%like'),
  eq: mockTransFunc('filters:result.eq'),
  ge: mockTransFunc('filters:result.ge'),
  gt: mockTransFunc('filters:result.gt'),
  in: mockTransFunc('filters:result.in'),
  le: mockTransFunc('filters:result.le'),
  less: mockTransFunc('filters:result.less'),
  like: mockTransFunc('filters:result.like'),
  'like%': mockTransFunc('filters:result.like%'),
  lt: mockTransFunc('filters:result.lt'),
  month: mockTransFunc('filters:result.month'),
  ne: mockTransFunc('filters:result.ne'),
  tday: mockTransFunc('filters:result.day'),
  teq: mockTransFunc('filters:result.eq'),
  week: mockTransFunc('filters:result.week'),
  valueNotFound: mockTransFunc('filters:valueNotFound')
}

export const FlowResults = {
  APPROVED: mockTransFunc('documents:flowResult.approved'),
  APPROVING: mockTransFunc('documents:flowResult.approving'),
  CANCELED: mockTransFunc('documents:flowResult.canceled'),
  CLOSED: mockTransFunc('documents:flowResult.closed'),
  DECLINED: mockTransFunc('documents:flowResult.declined'),
  ERROR: mockTransFunc('documents:flowResult.error'),
  REJECTED: mockTransFunc('documents:flowResult.rejected'),
  SIGNATURE_REJECTED: mockTransFunc('documents:flowResult.signatureRejected'),
  SIGNED: mockTransFunc('documents:flowResult.signed'),
  SOLVED: mockTransFunc('documents:flowResult.solved')
}

export const FlowStates = {
  COMPLETED: mockTransFunc('documents:flowState.completed'),
  IN_PROGRESS: mockTransFunc('documents:flowState.inProgress'),
  NOT_STARTED: mockTransFunc('documents:flowState.notStarted')
}

export const WorkflowStatuses = {
  ERROR: mockTransFunc('documents:workflowState.error'),
  NEED_TO_PROCESS: mockTransFunc('documents:workflowState.needToProcess'),
  SUCCESS: mockTransFunc('documents:workflowState.success'),
  WAITING_FOR_CONTRACTOR: mockTransFunc('documents:workflowState.waitingForContractor')
}

export const FlowStatuses = {
  ERROR: mockTransFunc('documents:flowStatus.error'),
  IN_PROGRESS: mockTransFunc('documents:flowStatus.inProgress'),
  NOT_STARTED: mockTransFunc('documents:flowStatus.notStarted'),
  SUCCESS: mockTransFunc('documents:flowStatus.success')
}

export const GroupFormMessages = {
  name: {
    required: mockTransFunc('group:form.errors.enterGroupName')
  },
  newMember: {
    required: mockTransFunc('group:modal.errors.enterNewMember')
  }
}

export const Handbooks = {
  errors: {
    key: mockTransFunc('handbooks:errors.keyRegexp'),
    name: mockTransFunc('handbooks:errors.nameRegexp'),
    sameName: mockTransFunc('handbooks:errors.sameName'),
    value: mockTransFunc('handbooks:errors.valueRegexp')
  },
  handbook: mockTransFunc('handbooks:handbook'),
  key: mockTransFunc('handbooks:key'),
  newValue: mockTransFunc('handbooks:newValue'),
  newLink: mockTransFunc('handbooks:newLink'),
  value: mockTransFunc('handbooks:value')
}

export const HandbooksSuggest = {
  empty: mockTransFunc('handbooks:suggest.empty'),
  hint: mockTransFunc('handbooks:suggest.hint'),
  placeholder: mockTransFunc('handbooks:suggest.placeholder')
}

export const Language = {
  en_us: mockTransFunc('language:en_us'),
  en_gb: mockTransFunc('language:en_gb'),
  en: mockTransFunc('language:en'),
  pt: mockTransFunc('language:pt'),
  ru: mockTransFunc('language:ru')
}

export const Locale = {
  en_US: mockTransFunc('language:en_us'),
  en_GB: mockTransFunc('language:en_gb'),
  pt_PT: mockTransFunc('language:pt'),
  ru_RU: mockTransFunc('language:ru')
}

export const MassiveActions = {
  APPROVE: mockTransFunc('massiveActions:approve'),
  COPY: mockTransFunc('massiveActions:copy'),
  DECLINE_SIGN: mockTransFunc('massiveActions:declineSign'),
  DECLINE_APPROVE: mockTransFunc('massiveActions:declineApprove'),
  DELETE: mockTransFunc('massiveActions:delete'),
  DELETE_INVITE: mockTransFunc('common:delete'),
  DOWNLOAD_DOCUMENTS: mockTransFunc('massiveActions:downloadDocuments'),
  DOWNLOAD_DOCUMENTS_FLOW: mockTransFunc('massiveActions:downloadDocumentsFlow'),
  FLOW_EDIT: mockTransFunc('massiveActions:flowEdit'),
  MODIFY: mockTransFunc('massiveActions:modify'),
  NEW_VERSION: mockTransFunc('massiveActions:newVersion'),
  RESEND: mockTransFunc('administrator:users.resend'),
  SIGN: mockTransFunc('massiveActions:sign'),
  SIGN_TICKET: mockTransFunc('massiveActions:signTicket'),
  SIGN_TITLE: mockTransFunc('massiveActions:signTitle'),
  START: mockTransFunc('massiveActions:start'),
  START_PROCESS: mockTransFunc('massiveActions:startProcess'),
  STOP_PROCESS: mockTransFunc('massiveActions:stopProcess')
}

export const Menu = {
  accessGroups: mockTransFunc('menu:accessGroups'),
  administration: mockTransFunc('menu:administration'),
  all: mockTransFunc('menu:all'),
  constraints: mockTransFunc('menu:constraints'),
  contractors: mockTransFunc('menu:contractors'),
  dictionaries: mockTransFunc('menu:dictionaries'),
  divisions: mockTransFunc('menu:divisions'),
  documentTypes: mockTransFunc('menu:documentTypes'),
  documents: mockTransFunc('menu:documents'),
  exchange: mockTransFunc('menu:exchange'),
  fields: mockTransFunc('menu:fields'),
  groups: mockTransFunc('menu:groups'),
  handbooks: mockTransFunc('menu:handbooks'),
  inProgress: mockTransFunc('menu:inProgress'),
  incoming: mockTransFunc('menu:incoming'),
  outgoing: mockTransFunc('menu:outgoing'),
  orgs: mockTransFunc('menu:orgs'),
  receipts: mockTransFunc('menu:receipts'),
  requisites: mockTransFunc('menu:requisites'),
  routes: mockTransFunc('menu:routes'),
  settings: mockTransFunc('menu:settings'),
  tasks: mockTransFunc('menu:tasks'),
  users: mockTransFunc('menu:users')
}

export const Messages = {
  all: mockTransFunc('messages:menu.all'),
  receipts: mockTransFunc('messages:menu.receipts'),
  incoming: mockTransFunc('messages:menu.incoming'),
  outgoing: mockTransFunc('messages:menu.outgoing')
}

export const MonthOptions = {
  nextmonth: mockTransFunc('filters:month.next'),
  prevmonth: mockTransFunc('filters:month.prev'),
  thismonth: mockTransFunc('filters:month.this')
}

export const NewOrganizationError = {
  alias: mockTransFunc('auth:regexpAlias'),
  common: mockTransFunc('auth:regexp'),
  name: mockTransFunc('auth:regexpName'),
  ogrn: mockTransFunc('auth:regexpOgrn')
}

export const NewOrganizationLabel = {
  alias: mockTransFunc('common:alias'),
  archive: mockTransFunc('auth:archive'),
  contractor: mockTransFunc('common:org'),
  email: mockTransFunc('common:email'),
  exchange: mockTransFunc('auth:exchange'),
  flow: mockTransFunc('auth:flow'),
  flowExchange: mockTransFunc('auth:flowExchange'),
  name: mockTransFunc('common:orgName'),
  nameShort: mockTransFunc('common:orgNameShort'),
  ogrnList: mockTransFunc('organizationList:ogrn'),
  password: mockTransFunc('common:password'),
  patronymic: mockTransFunc('common:patronymic'),
  phone: mockTransFunc('common:phone'),
  surname: mockTransFunc('common:surname'),
  cnpjError: mockTransFunc('organizationList:cnpjError')
}

export const Notices = {
  title: mockTransFunc('notification:notices.title'),
  tabs: {
    needToAction: mockTransFunc('notification:notices.tabs.needToAction'),
    info: mockTransFunc('notification:notices.tabs.info')
  },
  deleted: mockTransFunc('notification:notices.deleted'),
  categoryDeleted: mockTransFunc('notification:notices.categoryDeleted'),
  clear: mockTransFunc('notification:notices.clear'),
  actions: {
    newToDo: mockTransFunc('notification:notices.actions.newToDo'),
    newIncomingMessage: mockTransFunc('notification:notices.actions.newIncomingMessage'),
    newNotification: mockTransFunc('notification:notices.actions.newNotification'),
    flowFinished: mockTransFunc('notification:notices.actions.flowFinished'),
    newSendToDo: mockTransFunc('notification:notices.actions.newSendToDo')
  }
}

export const Notification = {
  confirmContent: mockTransFunc('notification:confirmContent'),
  confirmTitle: mockTransFunc('notification:confirmTitle'),
  errorContent: mockTransFunc('notification:errorContent'),
  errorTitle: mockTransFunc('notification:errorTitle'),
  successContent: mockTransFunc('notification:successContent'),
  successTitle: mockTransFunc('notification:successTitle'),
  copyContentText: mockTransFunc('notification:copyContentText')
}

export const OrganizationList = {
  searchRu: mockTransFunc('organizationList:search.ru'),
  searchGlobal: mockTransFunc('organizationList:search.global'),
  searchCombr: mockTransFunc('organizationList:search.combr'),
  searchCoza: mockTransFunc('organizationList:search.coza')
}

export const ProfileFields = {
  email: mockTransFunc('profile:field.email'),
  login: mockTransFunc('profile:field.login'),
  name: mockTransFunc('profile:field.name'),
  confirmPassword: mockTransFunc('profile:field.confirmPassword'),
  newPassword: mockTransFunc('profile:field.newPassword'),
  patronymic: mockTransFunc('profile:field.patronymic'),
  password: mockTransFunc('profile:field.password'),
  phone: mockTransFunc('profile:field.phone'),
  surname: mockTransFunc('profile:field.surname'),
  locale: mockTransFunc('profile:field.locale'),
  timeZone: mockTransFunc('profile:field.timeZone'),
  menu: mockTransFunc('profile:field.menu')
}

export const ProfileWarning = {
  password: mockTransFunc('profile:validation.password'),
  regexp: mockTransFunc('profile:validation.regexp')
}

export const ReceiptType = {
  RECIPIENT_CONFIRMATION: mockTransFunc('messages:receipts.recipientConfirmation'),
  RECIPIENT_CONFIRMATION_OPERATOR: mockTransFunc('messages:receipts.recipientConfirmationOperator'),
  OPERATOR_CONFIRMATION: mockTransFunc('messages:receipts.operatorConfirmation'),
  CORRECTION_REQUEST: mockTransFunc('messages:receipts.correctionRequest')
}

export const RequisitesLabel = {
  addrText: mockTransFunc('administrator:requisites.labels.addrText'),
  alias: mockTransFunc('administrator:requisites.labels.alias'),
  fnsParticipantCode: mockTransFunc('administrator:requisites.labels.fnsParticipantCode'),
  foreignAddress: mockTransFunc('administrator:requisites.labels.foreignAddress'),
  foreignCountry: mockTransFunc('administrator:requisites.labels.foreignCountry'),
  inn: mockTransFunc('common:inn'),
  isFlowFunctionalityEnabled: mockTransFunc('administrator:requisites.labels.isFlowFunctionalityEnabled'),
  kpp: mockTransFunc('common:kpp'),
  orgName: mockTransFunc('administrator:requisites.labels.name.org'),
  orgNameShort: mockTransFunc('administrator:requisites.labels.nameShort.org'),
  projectName: mockTransFunc('administrator:requisites.labels.name.project'),
  projectNameShort: mockTransFunc('administrator:requisites.labels.nameShort.project')
}

export const RequisitesWarning = {
  alias: mockTransFunc('administrator:requisites.validation.alias'),
  foreignAddress: mockTransFunc('administrator:requisites.validation.foreignAddress'),
  foreignCountry: mockTransFunc('administrator:requisites.validation.foreignCountry'),
  name: mockTransFunc('auth:regexpName'),
  ogrn: mockTransFunc('auth:regexpOgrn')
}

export const Roles = {
  ADMINISTRATOR: mockTransFunc('user:role.administrator'),
  ORGANIZATION: mockTransFunc('user:role.organization'),
  USER: mockTransFunc('user:role.user')
}

export const RolesPermissions = {
  administration: mockTransFunc('administrator:accessGroups.permissions.administration'),
  docCreate: mockTransFunc('administrator:accessGroups.permissions.docCreate'),
  docFieldChange: mockTransFunc('administrator:accessGroups.permissions.docFieldChange'),
  docOwner: mockTransFunc('administrator:accessGroups.permissions.docOwner.title'),
  docRemove: mockTransFunc('administrator:accessGroups.permissions.docRemove'),
  docRemoveProcess: mockTransFunc('administrator:accessGroups.permissions.docRemoveProcess'),
  docVisibility: mockTransFunc('administrator:accessGroups.permissions.docVisibility'),
  fieldSettings: mockTransFunc('administrator:accessGroups.permissions.fieldSettings.title'),
  noConstraint: mockTransFunc('administrator:accessGroups.permissions.noConstraint'),
  participant: mockTransFunc('administrator:accessGroups.permissions.participant.title'),
  participantDepartments: mockTransFunc('administrator:accessGroups.permissions.participantDepartments.title'),
  processOwner: mockTransFunc('administrator:accessGroups.permissions.processOwner.title')
}

export const RolesPermissionsTooltips = {
  fieldSettings: mockTransFunc('administrator:accessGroups.permissions.fieldSettings.tooltip'),
  docOwner: mockTransFunc('administrator:accessGroups.permissions.docOwner.tooltip'),
  participant: mockTransFunc('administrator:accessGroups.permissions.participant.tooltip'),
  participantDepartments: mockTransFunc('administrator:accessGroups.permissions.participantDepartments.tooltip'),
  processOwner: mockTransFunc('administrator:accessGroups.permissions.processOwner.tooltip')
}

export const Route = {
  accounting: mockTransFunc('route:accounting'),
  allDivisions: mockTransFunc('route:allDivisions'),
  allExpensesCenters: mockTransFunc('route:allExpensesCenters'),
  allExpensesTypes: mockTransFunc('route:allExpensesTypes'),
  assignMode: {
    ALL: mockTransFunc('route:assignMode.all'),
    ANY: mockTransFunc('route:assignMode.any'),
    placeholder: mockTransFunc('route:assignMode.placeholder')
  },
  assigneeActionType: {
    close: mockTransFunc('route:assigneeActionType.close'),
    assignToGroup: mockTransFunc('route:assigneeActionType.assignToGroup'),
    assignToOwner: mockTransFunc('route:assigneeActionType.assignToOwner'),
    assignToManager: mockTransFunc('route:assigneeActionType.assignToManager'),
    assignToUser: mockTransFunc('route:assigneeActionType.assignToUser'),
    placeholder: mockTransFunc('route:assigneeActionType.placeholder')
  },
  cancel: mockTransFunc('route:cancel'),
  errors: {
    groupExist: mockTransFunc('route:errors.stage.groupExist'),
    userExist: mockTransFunc('route:errors.stage.userExist'),
    stageName: mockTransFunc('route:errors.stage.stageNameRegexp'),
    hoursToProcess: mockTransFunc('route:errors.stage.hoursToProcess'),
    hoursToAutoAction: mockTransFunc('route:errors.assignee.hoursToAutoAction')
  },
  hint: mockTransFunc('route:hint'),
  hour: mockTransFunc('route:hour'),
  matching: mockTransFunc('route:matching'),
  notFound: mockTransFunc('route:notFound'),
  ownerRole: {
    AUTHOR: mockTransFunc('route:assignOwner.author'),
    GROUP: mockTransFunc('route:assignOwner.group'),
    INITIATOR: mockTransFunc('route:assignOwner.initiator'),
    MANAGER: mockTransFunc('route:assignOwner.manager'),
    USER: mockTransFunc('route:assignOwner.user')
  },
  selectUser: mockTransFunc('route:selectUser'),
  selectGroup: mockTransFunc('route:selectGroup'),
  selectDivision: mockTransFunc('route:selectDivision'),
  statement: mockTransFunc('route:statement')
}

export const RouteMessages = {
  regexp: mockTransFunc('route:invalidName'),
  selectAssignee: mockTransFunc('route:selectAssignee')
}

export const RouteOperators = {
  ge: mockTransFunc('route:operator.ge'),
  gt: mockTransFunc('route:operator.gt'),
  isNotNull: mockTransFunc('route:operator.isNotNull'),
  isNull: mockTransFunc('route:operator.isNull'),
  le: mockTransFunc('route:operator.le'),
  like: mockTransFunc('route:operator.like'),
  lt: mockTransFunc('route:operator.lt'),
  ne: mockTransFunc('route:operator.ne')
}

export const RouteOperatorLabels = {
  eq: mockTransFunc('route:operatorLabel.eq'),
  ge: mockTransFunc('route:operatorLabel.ge'),
  gt: mockTransFunc('route:operatorLabel.gt'),
  in: mockTransFunc('route:operatorLabel.in'),
  isNotNull: mockTransFunc('route:operatorLabel.isNotNull'),
  isNull: mockTransFunc('route:operatorLabel.isNull'),
  le: mockTransFunc('route:operatorLabel.le'),
  like: mockTransFunc('route:operatorLabel.like'),
  lt: mockTransFunc('route:operatorLabel.lt'),
  ne: mockTransFunc('route:operatorLabel.ne')
}

export const Scopes = {
  ORG: mockTransFunc('user:scope.org'),
  OTHER_SUB_ORGS: mockTransFunc('user:scope.otherSubOrgs'),
  SUB_ORG: mockTransFunc('user:scope.subOrg'),
  SUB_ORG_AND_BELOW: mockTransFunc('user:scope.subOrgAndBelow')
}

export const SignatureSignerPowersTranslations = {
  INVOICE_SIGNER: mockTransFunc('user:extendedSigner.signatureSignerPowers.invoiceSigner'),
  PERSON_MADE_OPERATION: mockTransFunc('user:extendedSigner.signatureSignerPowers.personMadeOperation'),
  MADE_AND_SIGN_OPERATION: mockTransFunc('user:extendedSigner.signatureSignerPowers.madeAndSignOperation'),
  PERSON_DOCUMENTED_OPERATION: mockTransFunc('user:extendedSigner.signatureSignerPowers.personDocumentedOperation'),
  MADE_OPERATION_AND_SIGNED_INVOICE: mockTransFunc('user:extendedSigner.signatureSignerPowers.madeOperationAndSignedInvoice'),
  MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE: mockTransFunc('user:extendedSigner.signatureSignerPowers.madeAndResponsibleForOperationAndSignedInvoice'),
  RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE: mockTransFunc('user:extendedSigner.signatureSignerPowers.responsibleForOperationAndSignerForInvoice')
}

export const SignatureSignerStatusTranslations = {
  EMPLOYEE: mockTransFunc('user:extendedSigner.signatureSignerStatus.employee'),
  INFORMATION_CREATOR_EMPLOYEE: mockTransFunc('user:extendedSigner.signatureSignerStatus.informationCreatorEmployee'),
  OTHER_ORGANIZATION_EMPLOYEE: mockTransFunc('user:extendedSigner.signatureSignerStatus.otherOrganizationEmployee'),
  AUTHORIZED_PERSON: mockTransFunc('user:extendedSigner.signatureSignerStatus.authorizedPerson')
}

export const SignatureSignerTypeTranslations = {
  LEGAL_ENTITY: mockTransFunc('user:extendedSigner.signatureSignerType.legalEntity'),
  INDIVIDUAL_ENTITY: mockTransFunc('user:extendedSigner.signatureSignerType.individualEntity'),
  PHYSICAL_PERSON: mockTransFunc('user:extendedSigner.signatureSignerType.physicalPerson')
}

export const SignatureStatus = {
  INVALID_SIGNATURE: mockTransFunc('document:signature.status.invalidSignature'),
  NOT_ACCEPTABLE: mockTransFunc('document:signature.status.notAcceptable'),
  VALID_SIGNATURE: mockTransFunc('document:signature.status.validSignature'),
  WAITING_FOR_CHECK: mockTransFunc('document:signature.status.waitingForCheck')
}

export const Stage = {
  approval: mockTransFunc('route:type.approval'),
  signing: mockTransFunc('route:type.signing')
}

export const Tasks = {
  complete: mockTransFunc('tasks:complete'),
  documents: mockTransFunc('tasks:documents'),
  incoming: mockTransFunc('tasks:incoming'),
  messages: mockTransFunc('tasks:messages'),
  outcoming: mockTransFunc('tasks:outcoming'),
  enterMessage: mockTransFunc('tasks:warning.enterMessage')
}

export const TasksIsRead = {
  true: mockTransFunc('tasks:displaying.true'),
  false: mockTransFunc('tasks:displaying.false')
}

export const TaskResult = {
  APPROVED: mockTransFunc('document:taskResult.approved'),
  AUTOCOMPLETED: mockTransFunc('document:taskResult.autocompleted'),
  CANCELLED: mockTransFunc('document:taskResult.canceled'),
  COMPLETED: mockTransFunc('document:taskResult.completed'),
  REASSIGNED: mockTransFunc('document:taskResult.reassigned'),
  REJECTED: mockTransFunc('document:taskResult.rejected'),
  SIGNED: mockTransFunc('document:taskResult.signed'),
  STOPPED: mockTransFunc('document:taskResult.stopped'),
  TODO: mockTransFunc('document:taskResult.todo')
}

export const TaskState = {
  IN_PROGRESS: mockTransFunc('document:taskState.inProgress'),
  NOT_STARTED: mockTransFunc('document:taskState.notStarted')
}

export const TaskType = {
  APPROVAL: 'document:taskType.approval',
  PROCESSING: 'document:taskType.processing',
  SIGNING: 'document:taskType.signing',
  SIGNING_SIMPLE: 'document:taskType.signingSimple',
  TODO: 'document:taskType.todo'
}

export const MassiveActionsTitle = {
  APPROVE: mockTransFunc('massiveActions:title.approve'),
  DECLINE_SIGN: mockTransFunc('massiveActions:title.declineSign'),
  DECLINE_APPROVE: mockTransFunc('massiveActions:title.declineApprove'),
  DELETE: mockTransFunc('massiveActions:title.delete'),
  MODIFY: mockTransFunc('massiveActions:title.modify'),
  SIGN: mockTransFunc('massiveActions:title.sign'),
  SIGN_TICKET: mockTransFunc('massiveActions:title.signTicket'),
  SIGN_TITLE: mockTransFunc('massiveActions:title.signTitle'),
  START_PROCESS: mockTransFunc('massiveActions:title.startProcess'),
  STOP_PROCESS: mockTransFunc('massiveActions:title.stopProcess')
}

export const User = {
  contacts: mockTransFunc('user:contacts'),
  email: mockTransFunc('user:email'),
  login: mockTransFunc('user:login'),
  name: mockTransFunc('user:name'),
  patronymic: mockTransFunc('user:patronymic'),
  phone: mockTransFunc('user:phone'),
  position: mockTransFunc('user:position'),
  readonlyLabel: mockTransFunc('user:readonlyLabel'),
  roleLabel: mockTransFunc('user:roleLabel'),
  scopeLabel: mockTransFunc('user:scopeLabel'),
  surname: mockTransFunc('user:surname'),
  user: mockTransFunc('user:user')
}

export const UserCertificate = {
  issue: mockTransFunc('user:certificate.issue'),
  reissue: mockTransFunc('user:certificate.reissue')
}

export const UserCertificateErrors = {
  address: mockTransFunc('user:certificate.error.address'),
  inn: mockTransFunc('user:certificate.error.inn'),
  passport: mockTransFunc('user:certificate.error.passport'),
  snils: mockTransFunc('user:certificate.error.snils')
}

export const UserCertificateModalHeader = {
  info: mockTransFunc('user:certificate.header.info'),
  issue: mockTransFunc('user:certificate.header.issue'),
  reissue: mockTransFunc('user:certificate.header.reissue'),
  revoke: mockTransFunc('user:certificate.header.revoke')
}

export const UserExtendedSigner = {
  signatureSignerInfo: mockTransFunc('user:extendedSigner.signatureSignerInfo'),
  signatureSignerOrgPowersBase: mockTransFunc('user:extendedSigner.signatureSignerOrgPowersBase'),
  signatureSignerPowersBase: mockTransFunc('user:extendedSigner.signatureSignerPowersBase'),
  signatureSignerPowersBuyer: mockTransFunc('user:extendedSigner.signatureSignerPowersBuyer'),
  signatureSignerPowersSeller: mockTransFunc('user:extendedSigner.signatureSignerPowersSeller'),
  signatureSignerStatusLabel: mockTransFunc('user:extendedSigner.signatureSignerStatusLabel'),
  signatureSignerTypeLabel: mockTransFunc('user:extendedSigner.signatureSignerTypeLabel')
}

export const UserNotificationSettings = {
  all: mockTransFunc('user:notificationSettings.all'),
  email: mockTransFunc('user:notificationSettings.email'),
  flow: {
    ANY_CHANGES: mockTransFunc('user:notificationSettings.flow.any'),
    FINISHED_ONLY: mockTransFunc('user:notificationSettings.flow.finished'),
    label: mockTransFunc('user:notificationSettings.flow.label'),
    NONE: mockTransFunc('user:notificationSettings.flow.none')
  },
  heading: mockTransFunc('user:notificationSettings.heading'),
  mobile: mockTransFunc('user:notificationSettings.mobile'),
  newMessage: mockTransFunc('user:notificationSettings.newMessage'),
  newPatch: mockTransFunc('user:notificationSettings.newPatch'),
  additionally: {
    notify: mockTransFunc('user:notificationSettings.additionally.notify'),
    processChanges: mockTransFunc('user:notificationSettings.additionally.processChanges'),
    completion: mockTransFunc('user:notificationSettings.additionally.completion')
  },
  daily: mockTransFunc('user:notificationSettings.daily')
}

export const UserCertificateParam = {
  approve_second_factor: {
    EMAIL: mockTransFunc('user:certificate.param.approve_second_factor.email'),
    label: mockTransFunc('user:certificate.param.approve_second_factor.label'),
    NONE: mockTransFunc('user:certificate.param.approve_second_factor.none'),
    SMS: mockTransFunc('user:certificate.param.approve_second_factor.sms')
  },
  is_request_by_sms: mockTransFunc('user:certificate.param.is_request_by_sms')
}

export const UserCertificatePowerOfAttorneyType = {
  DIGITAL: mockTransFunc('crypto:PoA.type.digital'),
  OTHER: mockTransFunc('crypto:PoA.type.other'),
  PAPER: mockTransFunc('crypto:PoA.type.paper')
}

export const UserCertStatusTranslations = {
  BLOCKED: mockTransFunc('user:certificate.status.blocked'),
  CONFIRMED: mockTransFunc('user:certificate.status.confirmed'),
  WAITING_CONFIRMATION: mockTransFunc('user:certificate.status.waitingConfirmation')
}

export const UsersExport = {
  accessibleGroups: mockTransFunc('administrator:users.groups'),
  additionalInfo1: mockTransFunc(['common:additionalField', '1']),
  additionalInfo2: mockTransFunc(['common:additionalField', '2']),
  createdDate: mockTransFunc('administrator:users.createdDate'),
  createdUser: mockTransFunc('administrator:users.created'),
  documentTypeAccessGroup: mockTransFunc('administrator:users.roleRestrictions'),
  email: mockTransFunc('user:email'),
  isFederationUser: mockTransFunc('user:federationUser'),
  lastActivity: mockTransFunc('administrator:users.lastActivity'),
  lastActivityIP: mockTransFunc('administrator:users.lastIP'),
  locale: mockTransFunc('profile:field.locale'),
  name: mockTransFunc('user:name'),
  patronymic: mockTransFunc('user:patronymic'),
  phone: mockTransFunc('user:phone'),
  position: mockTransFunc('user:position'),
  role: mockTransFunc('administrator:users.accessRole'),
  scope: mockTransFunc('user:scopeLabel'),
  status: mockTransFunc('user:statusTitle'),
  subOrg: mockTransFunc('user:subOrg'),
  surname: mockTransFunc('user:surname'),
  timeZone: mockTransFunc('profile:field.timeZone')
}

export const UserReadonlyTranslations = {
  false: mockTransFunc('user:readonly.false'),
  true: mockTransFunc('user:readonly.true')
}

export const UserStatuses = {
  ACTIVE: mockTransFunc('user:status.active'),
  BLOCKED: mockTransFunc('user:status.blocked')
}

export const UserValidation = {
  phone: mockTransFunc('user:validation.phone')
}

export const UserWarning = {
  email: mockTransFunc('user:validation.email'),
  login: mockTransFunc('user:validation.login'),
  passwordRegexp: mockTransFunc('user:validation.passwordRegexp'),
  positionRegexp: mockTransFunc('user:validation.positionRegexp'),
  regexp: mockTransFunc('user:validation.regexp'),
  additionalFieldRegexp: mockTransFunc('user:validation.additionalFieldRegexp'),
  invalidPhoneNumber: mockTransFunc('common:warning.phone')
}

export const UtdWarning = {
  position: mockTransFunc('utd:warning.position'),
  signatureSignerPowers: mockTransFunc('utd:warning.signatureSignerPowers'),
  signatureSignerStatus: mockTransFunc('utd:warning.signatureSignerStatus'),
  signatureSignerType: mockTransFunc('utd:warning.signatureSignerType'),
  signatureSignerPowersBase: mockTransFunc('utd:warning.signatureSignerPowersBase'),
  signatureSignerOrgPowersBase: mockTransFunc('utd:warning.signatureSignerOrgPowersBase')
}

export const Warning = {
  email: mockTransFunc('common:warning.email'),
  phone: mockTransFunc('common:warning.phone'),
  equal: {
    9: mockTransFunc('common:warning.equal.9')
  },
  max: {
    12: mockTransFunc('common:warning.max.12'),
    16: mockTransFunc('common:warning.max.16'),
    18: mockTransFunc('common:warning.max.18'),
    19: mockTransFunc('common:warning.max.19'),
    20: mockTransFunc('common:warning.max.20'),
    25: mockTransFunc('common:warning.max.25'),
    26: mockTransFunc('common:warning.max.26'),
    30: mockTransFunc('common:warning.max.30'),
    32: mockTransFunc('common:warning.max.32'),
    40: mockTransFunc('common:warning.max.40'),
    50: mockTransFunc('common:warning.max.50'),
    60: mockTransFunc('common:warning.max.60'),
    100: mockTransFunc('common:warning.max.100'),
    120: mockTransFunc('common:warning.max.120'),
    128: mockTransFunc('common:warning.max.128'),
    150: mockTransFunc('common:warning.max.150'),
    250: mockTransFunc('common:warning.max.250'),
    255: mockTransFunc('common:warning.max.255'),
    500: mockTransFunc('common:warning.max.500'),
    1000: mockTransFunc('common:warning.max.1000'),
    2000: mockTransFunc('common:warning.max.2000')
  },
  min: {
    1: mockTransFunc('common:warning.min.1'),
    2: mockTransFunc('common:warning.min.2'),
    3: mockTransFunc('common:warning.min.3'),
    4: mockTransFunc('common:warning.min.4'),
    6: mockTransFunc('common:warning.min.6'),
    8: mockTransFunc('common:warning.min.8'),
    10: mockTransFunc('common:warning.min.10'),
    20: mockTransFunc('common:warning.min.20')
  },
  regexp: mockTransFunc('common:warning.regexp'),
  regexp_required: mockTransFunc('common:warning.regexp_required'),
  required: mockTransFunc('common:warning.required'),
  id: mockTransFunc('common:warning.id')
}

export const WeekOptions = {
  nextweek: mockTransFunc('filters:week.next'),
  prevweek: mockTransFunc('filters:week.prev'),
  thisweek: mockTransFunc('filters:week.this')
}

export const HandbooksPlaceholders = {
  name: mockTransFunc('handbooks:placeholder.name'),
  key: mockTransFunc('handbooks:placeholder.key')
}

export const PresetValuesLabels = {
  author: mockTransFunc('route:presetValues.author'),
  initiator: mockTransFunc('route:presetValues.initiator'),
  suborgManager: mockTransFunc('route:presetValues.suborgManager'),
  initiatorManager: mockTransFunc('route:presetValues.initiatorManager')
}

export const MenuModeLabels = {
  vertical: mockTransFunc('profile:menu.left'),
  horizontal: mockTransFunc('profile:menu.top')
}

export const UserSuggest = {
  empty: mockTransFunc('filters:suggestUser.empty'),
  placeholder: mockTransFunc('filters:suggestUser.placeholder'),
  users: mockTransFunc('filters:suggestUser.users')
}

export const Recognition = {
  enable: mockTransFunc('preProcessing:enable'),
  error: {
    addPreProcessing: mockTransFunc('preProcessing:error:addPreProcessing'),
    addTemplate: mockTransFunc('preProcessing:error:addTemplate'),
    cardType: mockTransFunc('preProcessing:error:cardType'),
    change: mockTransFunc('preProcessing:error:change'),
    format: mockTransFunc('preProcessing:error:format'),
    notFound: mockTransFunc('preProcessing:error:notFound'),
  },
  insertExample: mockTransFunc('preProcessing:insertExample'),
  in: mockTransFunc('preProcessing:in'),
  not: mockTransFunc('preProcessing:not'),
  recognize: mockTransFunc('preProcessing:recognize'),
  sec: mockTransFunc('preProcessing:sec'),
  settings: mockTransFunc('preProcessing:settings'),
  status: {
    IN_PROGRESS: mockTransFunc('preProcessing:status:recognition'),
    ERROR: mockTransFunc('preProcessing:status:error'),
    COMPLETED: mockTransFunc('preProcessing:status:recognized'),
    IN_QUEUE: mockTransFunc('preProcessing:status:waiting'),
    NOT_APPLICABLE: mockTransFunc('preProcessing:status:notApplicable'),
    STOPPED: mockTransFunc('preProcessing:status:stopped'),
  },
  properties: {
    ocrLang: mockTransFunc('preProcessing:properties:ocrLang'),
    ocrType: mockTransFunc('preProcessing:properties:ocrType'),
    ocrVolume: mockTransFunc('preProcessing:properties:ocrVolume'),
    isSearchablePdf: mockTransFunc('preProcessing:properties:isSearchablePdf'),
    AI: {
      title: mockTransFunc('preProcessing:properties:ai:title'),
      fields: {
        all: mockTransFunc('preProcessing:properties:ai:fields:all'),
        specified: mockTransFunc('preProcessing:properties:ai:fields:specified'),
        function: mockTransFunc('preProcessing:properties:ai:fields:function'),
        functionTitle: mockTransFunc('preProcessing:properties:ai:fields:functionTitle'),
        function2: mockTransFunc('preProcessing:properties:ai:fields:function2'),
      },
      modelType: mockTransFunc('preProcessing:properties:ai:modelType'),
      executionMode: mockTransFunc('preProcessing:properties:ai:executionMode'),
      isAddDictionary: mockTransFunc('preProcessing:properties:ai:isAddDictionary'),
      messageContent: mockTransFunc('preProcessing:properties:ai:messageContent'),
      pythonScript: mockTransFunc('preProcessing:properties:ai:pythonScript'),
      pythonScriptTitle: mockTransFunc('preProcessing:properties:ai:pythonScriptTitle'),
      limitTokens: mockTransFunc('preProcessing:properties:ai:limitTokens'),
    }
  }
}

export const UserIsFederation = {
  FEDERATION: mockTransFunc('user:federation'),
  NON_FEDERATION: mockTransFunc('user:federationNon'),
}
