export default {
  attachments: {
    add: 'documents/{{ oguid }}/attachments/file',
    attachSignature: 'documents/{{ documentOguid }}/attachments/signatures',
    correctionRequest: 'documents/correctionRequest',
    documentFile: 'attachments/{{ oguid }}/file',
    documentFileByToken: '/files/orgs/{{ orgOguid }}/attachments/{{ attachmentOguid }}/{{ token }}',
    documentsFiles: 'documents/files',
    fileField: 'documents/{{ oguid }}/fileField/{{ fieldKey }}',
    fileFieldActions: {
      array: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/index/{{ index }}/checkOut/{{ action }}',
      single: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/checkOut/{{ action }}'
    },
    fileFieldByIndex: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/index/{{ index }}',
    fileFieldGet: {
      array: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/index/{{ index }}/file',
      single: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/file'
    },
    fileFieldHistory: {
      array: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/index/{{ index }}/history',
      single: 'documents/{{ oguid }}/fileField/{{ fieldKey }}/history'
    },
    getTicketReceipt: 'attachments/{{ attachmentOguid }}/generateTicketReceiptXml',
    get: 'documents/{{ oguid }}/attachments/files',
    hash: 'attachments/hash',
    patchMessage: 'messagesPatch',
    printPdf: 'documents/{{ documentOguid }}/printPdf',
    shelf: 'shelf',
    sendSign: 'shelfSignatureBase64',
    templateFile: 'attachments/generateTemplate/{{ typeKey }}'
  },
  constraints: {
    add: 'documents/constraints',
    list: 'documents/constraints',
    single: 'documents/constraints/{{ oguid }}'
  },
  contractors: {
    addForeign: 'contractors/manual/foreign',
    addByHid: 'contractors/EGRUL',
    addByOguid: 'contractors/org',
    addManual: 'contractors/manual',
    delete: 'contractors/{{ oguid }}',
    get: 'contractors/{{ oguid }}',
    getById: 'suggest/contractors/findById',
    edit: 'contractors/{{ oguid }}',
    editForeign: 'contractors/manual/foreign/{{ oguid }}',
    editManual: 'contractors/manual/{{ oguid }}',
    list: 'contractors',
    suggest: 'suggest/contractors'
  },
  documents: {
    flow: {
      state: 'flows/entities/{{ flowEntityOguid }}/flowState',
      stop: 'flows/entities/{{ flowEntityOguid }}/flowStop',
      tasks: 'flows/entities/{{ flowEntityOguid }}/tasks',
      todos: 'flows/entities/{{ flowEntityOguid }}/todos'
    },
    list: {
      documents: 'documents',
      exportExcel: 'documents/exportExcel',
      exportFiles: 'documents/exportFiles',
      messages: 'messages',
      receipts: 'documents/receipts'
    },
    relation: {
      list: 'documents/{{ documentOguid }}/relations',
      single: 'documents/{{ documentOguid }}/relations/{{ relatedDocumentOguid }}'
    },
    single: {
      document: 'documents/{{ documentOguid }}',
      getAttachPreview: 'documents/{{ documentOguid }}/page/{{ documentPage }}',
      getJobFile: 'jobFiles/{{ jobOguid }}',
      getShelfPreview: 'shelf/{{ fileOguid }}/page/{{ documentPage }}',
      getSignatureInfo: '/attachments/{{ signatureId }}/signatureInfo',
      sendFile: 'shelf',
      documentId: 'documents/documentId',
      sharedFileId: 'documents/sharedFileLink/'
    },
    fileRequestsWithToken: ['previewPDF', 'printPdf', 'attachments', 'documentOguids']
  },
  divisions: {
    list: 'subOrgs',
    single: 'subOrgs/{{ oguid }}'
  },
  dsc: {
    requestCertificate: 'users/{{ oguid }}/docuForceCertificateRequest',
    deleteCertificateRequest: 'users/{{ oguid }}/docuForceCertificateRequest',
    repeatCertificateRequest: 'users/{{ oguid }}/docuForceCertificateRequest/repeat',
    getCertificate: 'users/{{ oguid }}/certificate',
    printCertificate: 'users/{{ oguid }}/docuForceCertificatePrintForm/{{ certificateOguid }}',
    revokeCertificate: 'users/{{ oguid }}/docuForceCertificateRevocation',
    signFileHash: 'users/{{ oguid }}/docuForceCertificate/{{ certificateOguid }}/sign'
  },
  dss: {
    getCertificates: 'user/dssCertificates',
    getSignature: 'dssSign/getSignature',
    startTransaction: 'dssSign/startTransaction'
  },
  federations: {
    main: 'federations',
    add: 'federations/add',
    modify: 'federations/{{ federationOguid }}',
  },
  flows: {
    complete: 'flows/tasks/{{ taskOguid }}/complete',
    getDraftDocumentScheme: 'flows/entities/flowStart',
    getScheme: 'flows/entities/{{ flowEntityOguid }}/flowStart',
    getTasks: 'flows/tasks',
    getAllTasks: 'services/flows/tasks',
    getNotices: 'user/notifications/',
    markAsRead: 'flows/tasks/{{ taskOguid }}/markAsRead',
    markIsRead: 'flows/tasks/markIsRead',
    markNoticeIsRead: 'user/notifications/{{ notificationOguid }}/isRead',
    markNoticeCategoryIsRead: 'user/notifications/category/{{ categoryType }}/isRead',
    sendMessage: 'flows/entities/{{ documentOguid }}/tasks',
    tasksQuantities: 'flows/tasksQuantities',
    noticesQuantities: 'user/notifications/count',
    getTasksAllOrgs: 'services/flows/tasks',
    messagesQuantities: 'orgs/{{ orgOguid }}/messages/quantity',
    allTasksQuantities: 'services/flows/tasksQuantities',
  },
  handbooks: {
    file: {
      related: 'dicts/{{ dictType }}/relations/file',
      values: 'dicts/{{ dictType }}/values/file'
    },
    list: {
      names: 'dicts',
      related: 'dicts/{{ dictType }}/values/{{ key }}/relations/{{ relatedDictType }}',
      relations: 'dicts/{{ dictType }}/values/relations',
      values: 'dicts/{{ dictType }}/values'
    },
    single: {
      related: 'dicts/{{ dictType }}/values/{{ key }}/relations/{{ relatedDictType }}/{{ relatedKey }}',
      relations: 'dicts/{{ dictType }}/values/{{ key }}/relations',
      remove: 'dicts/{{ dictType }}',
      value: 'dicts/{{ dictType }}/values/{{ key }}'
    }
  },
  groups: {
    list: 'groups',
    members: {
      add: 'groups/{{ oguid }}/members',
      remove: 'groups/{{ oguid }}/members/{{ memberOguid }}'
    },
    single: 'groups/{{ oguid }}'
  },
  metadata: {
    accessGroups: {
      list: 'documents/types/accessGroups',
      roleGroup: {
        edit: 'documents/types/role/{{ userRole }}/accessGroup/{{ oguid }}',
        get: 'documents/types/role/{{ userRole }}/accessGroup'
      },
      single: 'documents/types/accessGroups/{{ oguid }}'
    },
    dicts: 'dicts',
    documents: {
      history: 'documents/historyStateCodes',
      types: 'documents/types',
      work: 'documents/workflowStatuses'
    },
    documentTypes: {
      list: 'documentTypes',
      single: 'documentTypes/{{ documentTypeKey }}',
      template: 'documentTypes/{{ documentTypeKey }}/template'
    },
    docTypeGroups: {
      list: 'documentGroup',
      single: 'documentGroup/{{ oguid }}'
    },
    fields: {
      list: 'fields',
      single: 'fields/{{ fieldKey }}',
      sort: 'fields/sortOrder'
    },
    userDocTypes: 'documents/userTypes',
    routes: {
      names: 'routes/flowStageNames',
      types: 'routes/flowStageTypes'
    }
  },
  preProcessing: {
    create: 'preProcessingJobs',
    status: 'preProcessingJobs/{{ preProcessingJobOguid }}',
    stopJob: 'preProcessingJobs/{{ preProcessingJobOguid }}/stop',
    startDoc: 'preProcessingJobs/document/{{ documentOguid }}/start',
    stopDoc: 'preProcessingJobs/document/{{ documentOguid }}/stop',
    matching: '/documents/fieldsMatching/{{ isAddDictionary }}'
  },
  routes: {
    list: 'routes',
    single: 'routes/{{ oguid }}'
  },
  suggest: {
    contractors: {
      findById: 'suggest/contractors/findById',
      list: 'suggest/contractors'
    }
  },
  userFilters: {
    list: 'userFilters',
    single: 'userFilters/{{ oguid }}'
  },
  users: {
    invites: {
      inviteExisting: 'users/{{ userOguid }}/invite',
      inviteNew: 'users/invite',
      list: 'users/invites',
      repeat: 'users/invitesRepeat',
      single: 'users/invites/{{ inviteOguid }}'
    },
    list: 'users',
    logo: 'logo',
    orgs: {
      list: 'orgs',
      single: 'orgs/{{ orgOguid }}'
    },
    passwordReset: 'password-reset',
    recipients: 'recipients',
    signIn: 'signin',
    signUp: 'signup',
    SSOAuth: 'tokens',
    SSOFederations: 'sso/federations/{{ oguid }}',
    SSORegistrations: 'web/registrations/{{ oguid }}',
    phoneVerify: 'phoneAuthInit',
    phoneAuthConfirm: 'phoneAuthConfirm',
    factorAuthConfirm: 'factorAuthConfirm',
    single: {
      access: 'user',
      details: 'users/{{ oguid }}',
      settings: 'user/settings'
    },
    tokenRefresh: 'tokenRefresh',
    generateAPIKey: 'generateAPIKey'
  },
  utd: {
    buyer: 'documents/UpdBuyerTitleInfo',
    seller: 'documents/UpdSellerTitleInfo'
  },
  verify: {
    confirm: 'verify/phoneCheckConfirm',
    init: 'verify/phoneCheckInit'
  }
}
